import { formatMoney, getProductImage } from '@/lib/util'
import inViewport from 'in-viewport'
import '../reviews-rating/reviews-rating.vue'

export default {
  data () {
    return {
      isHover: false
    }
  },
  computed: {
    /**
     * Compiles a list of all available colors under the
     * product.
     */
    allColors () {
      return this.getOptionValuesByName('color')
    },
    /**
     * This determines which color should be shown
     * be the active color, and thus dictate which
     * image should be shown to the user.
     */
    activeColor () {
      if (this.isColorSelected && this.colorSelected) {
        return this.colorSelected
      }
      if (this.firstAvailableVariant && Object.keys(this.firstAvailableVariant).length) {
        const colorIndex = this.getOptionIndexByName('color') || 1
        if (colorIndex) return this.firstAvailableVariant[`option${colorIndex}`]
      }
      return (this.allColors || [])[0]
    },
    imageSrc () {
      return getProductImage('color', this.activeColor, this.images, false, this.activeVariantImage)
    },
    link () {
      return `${this.productWithinCollectionUrl}${this.activeVariantUrl}`
    },
    activeVariant () {
      return (this.variants && this.variants.length)
        ? this.variants.find(({title = '', id}) => {
          return ~title.indexOf(this.activeColor)
        })
        : null
    },
    activeVariantUrl () {
      return (this.activeVariant ? `?variant=${this.activeVariant.id}` : '')
    },
    activeVariantImage () {
      return this.activeVariant && this.activeVariant.hasOwnProperty('image')
        ? this.activeVariant.image
        : this.featuredImage
    },
    productPrice () {
      return this.activeVariant
        ? this.activeVariant.price
        : this.price
    },
    productCompareAtPrice () {
      return this.activeVariant
        ? this.activeVariant.compareAtPrice
        : this.compareAtPrice
    },
    formattedPrice () {
      return `${formatMoney(this.productPrice)}`
    },
    formattedCompareAtPrice () {
      return formatMoney(this.productCompareAtPrice)
    },
    isOnSale () {
      if (this.activeVariant) {
        return this.activeVariant.compareAtPrice > this.activeVariant.price
      }

      return this.compareAtPrice > this.price
    },
    variantsAvailable () {
      return this.variants.length
    },
    ratioStyles () {
      const comparingImageSrc = this.imageSrc
        .replace(/(^\w+:|^)\/\//, '') // remove protocols (http:// or https://) from url
        .replace(/\.[^/.]+$/, '') // remove file extension from url

      const currentMedia = (this.media || []).find(media => {
        const comparingMediaSrc = media.src
          .replace(/(^\w+:|^)\/\//, '') // remove protocols from  (http:// or https://)url
          .replace(/\.[^/.]+$/, '') // remove file extension from url
        return ~comparingImageSrc.indexOf(comparingMediaSrc)
      })
      if (currentMedia) {
        return {
          paddingBottom: `${100 / currentMedia.aspect_ratio}%`,
          '--aspect-ratio': currentMedia.aspect_ratio
        }
      }
    },
    filteredVariants () {
      return this.variants && this.variants.length
        ? this.variants.filter(({title = '', id}) => {
          return ~title.indexOf(this.activeColor)
        })
        : null
    },
    filteredOptionsWithValues () {
      if (this.product['title'] && this.product['title'].toLowerCase().indexOf('gift cart')) {
        return this.optionsWithValues
      } else {
        return this.optionsWithValues.map((option, index) => {
          if (option.name.toLowerCase() !== 'color') {
            const currentOption = {...option}
            currentOption.values = this.filteredVariants.reduce((arr, variant) => {
              const optionIndex = `option${option.position}`
              if (arr.indexOf(variant[optionIndex]) === -1) {
                const outOfStock = (!variant.available) ? ' - Out of Stock' : ''
                arr.push(variant[optionIndex] + outOfStock)
              }
              return arr
            }, [])
            return currentOption
          }
          return option
        })
      }
    },
    setupHover () {
      return this.media && this.media.length > 1 ? this.media[1] : null
    },
    imageHover () {
      if (!this.setupHover) return null
      if (this.setupHover && this.isHover) {
        return this.setupHover.src
      }

      return this.imageSrc
    }
  },
  methods: {
    formatMoney,
    /**
     * Allows the user to manually select
     * a color to show a variant image. This action
     * will override colors that are shown from other means,
     * such as a PLP color filter being clicked.
     *
     * @param {*} color
     */
    updateActiveColor (color) {
      this.isColorSelected = true
      this.colorSelected = color
    },
    getOptionByName (name) {
      return (this.optionsWithValues || []).filter(option => {
        return option.name.toLowerCase() === name.toLowerCase()
      })
    },
    getOptionIndexByName (name) {
      const option = this.getOptionByName(name)
      if (option && option.length) return option[0].position
    },
    getOptionValuesByName (name) {
      const option = this.getOptionByName(name)
      if (option && option.length) return option[0].values
    },
    updateProductOptions (obj) {
      this.selectedOptions[obj.name] = obj.value
    },
    scrollHandler () {
      this.inViewport = Boolean(inViewport(this.$el))
    },
    getFileName (fullPath) {
      // eslint-disable-next-line no-useless-escape
      return fullPath ? fullPath.replace(/^.*[\\\/]/, '') : fullPath
    },
    showDetails () {
      this.showModal = true
    },
    closeModal () {
      this.showModal = false
    }
  }
}
